

@media (max-width: 991px) {
 .navbar .navbar-nav .dropdown-menu {
    top: 0;
  }
}


@media (min-width: 992px){

.logo-holder{
	position: absolute;
	text-align: center;
	width:600px;
	height:40px;
	left:50%;
	margin-top:-54px;
	margin-left:-300px;
	.title{
		font-size: 30px;
		span{
			font-weight: 200;
		}
	}
}
p img{
	max-width: 100%;
}
#main_nav{
	margin-top:40px;
}
.navbar{
	height: 120px;
	
}

}
.navbar-toggler {

    margin-top: 32px;
}
.spacer{
	height:120px;
}

.footertekst h2 span{
	font-weight: 300;
	border-right: 1px solid $black-color;
	padding-right:10px;
}
.footertekst .company-link{
	margin-left:10px;
}

.steur-logo{
	position: fixed;
	top:50%;
	margin-left:20px;
	margin-top:-100px;
	width:568px;
	height: 153px;
	z-index: 9999;
}
@media (max-width: 1250px){	
	.steur-logo img{
		width:468px;
		height:auto !important;
		}
}
@media (max-width: 1130px){	
	.steur-logo img{
		width:368px;
		height:auto !important;
		}
}
.ekko-lightbox .modal-header h4{
	margin-top:0px !important;
	text-transform: capitalize;
}
.ekko-lightbox .modal-dialog .modal-content {
    background: #fff !important;
    /* border: 5px solid white; */
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.35);
    border-radius: 0;
}
.ekko-lightbox button.close {
    margin-top: -28px;
}
.ekko-lightbox-nav-overlay a span{
	color:#fff;
}

@media (max-width: 576px){
	.hide{
		display: none;
	}
}

.footer-big  hr{
   margin-top: 16px;
    }
h2 a.company-link{
	color:#aaa !important;
}
h2 a.company-link:hover{
	color:$black-color !important;
}

/* MINIgallery */

#gallerywrapper{
	width:100%;
	background-color: #fff;
	padding:10px 0;
	
}

#carousel {
	width: 100%;
	overflow: hidden;
}
#carousel .imageholder {
	display: block;
	float: left;
	margin-left:5px;
	margin-right:5px;
	
}

#carousel .imageholder{
	background-size: cover;
	background-position: center center;
	height:180px;
	width:auto;
	overflow: hidden;
	position: relative;
	display: block;
}
#carousel .imageholder img{
	position: absolute;
	width:100%;
  /* Position the image in the middle of its container. */
  top: -9999px;
  right: -9999px;
  bottom: -9999px;
  left: -9999px;
  margin: auto;
  /* The following values determine the exact image behaviour. */
  /* You can simulate background-size: cover/contain/etc.
     by changing between min/max/standard width/height values.
     These values simulate background-size: cover
  */
}

.imageholder .overlay {
	position: absolute;
	background: #000;
	background: rgba(0,0,0,0.1);
	width: 100%;
	height: 100%;
	/*padding: 20px;*/
	top:0;
	left:0;
	
}


/* end */
.page-header.page-header-31 {
    min-height: auto;
    height: auto;
    max-height: auto;
    padding-top: 56.25%;
}

.text-space{
	margin-bottom:-20px;
	margin-top:10px;
}

.logo-video{
	float: left;
	margin-bottom:0px;
	margin-top:15px;
	margin-right:20px;
	display: inline-block;
}
.title-img-holder-videopage{
	overflow: hidden;
	margin-top:15px;
	margin-bottom:-10px;
}

.titleborder {
    width: 139px;
    /* margin-left: 5px; */
    margin-top: 20px;
    border-bottom: 1px solid #232323;
    margin-bottom: 9px;
}
.c-holder{
	.title{	        
	    line-height: normal;
	 
	}
	.title span{
	    font-weight: bold;
	    color: #000;
	}
	.titleholder-fixed{
		height:95px;
		margin-top: 37px;
	}
	
	h3{
		font-size:25px;
		font-weight: bold;
	    color: #000;
	    @media (max-width: 767px){
		  font-size:20px;
		  margin-top:20px !important;
		  }  
		
	}

}

.titleholder img, p img{
	float:left;
	margin-right:10px;
	margin-top:-8px;
}

.titleholder a{
		color:#000 !important;
	}
.mt40{
	margin-top:40px;
}
.mt0{
	margin-top:0px !important;
}
.mb30{
	margin-bottom:20px !important;
}





/* Home page */

.c-holder{
	position: relative;
	background-color: transparent;
	padding:0px;
	margin:20px 0 40px 0;
	@media (max-width: 576px){
		margin:20px 0 80px 0;
		}
	
	
	.c-holder-title{
		margin-top:0px;
		margin-bottom:20px;
		font-weight: bold;
		font-size: 34px;
		span{
			color:#000;
		}
	}
}
a.mainlink{
		
		position: absolute;
		right:30px;
		top:34px;
		font-size: 34px;
		color:#B0B0B0;
		
	}

a:hover, a:focus{
	color:#000;
	
}

p a{
	color: #666;
	font-weight: 700;
}
p a:hover{
	color: $black-color;
}
p b, p strong{
	font-weight: 700;

}
footer a, footer a:hover{
	color:$black-color;
}

/* Portfolio */

.navbar-filter{
	margin-bottom: 40px;
	/*margin-top:40px;*/
	
	p a{
		text-decoration:none !important; 
	}
	p{
		font-size: 1.25em;
		color:$black-color !important;
	}
}

.filter-button-group a.current, .filter-button-group a:hover {
    font-weight: 300;
    color: $black-color !important;
}
.filter-button-group a {
    margin-left: 5px;
    margin-right: 5px;
	font-size: 0.8em !important;
    color: #aaa;
    font-weight: 400 !important;
    background-color: transparent;
    //text-transform: uppercase;
    //border: 1px solid #eee;
    //padding:5px 10px 5px 10px;
       
}

.grid{
	margin-left:-5px;
}
.grid .imageholder{
	background-size: cover;
	background-position: center center;
	width:100%;
	padding-top: 56.25%;
	overflow: hidden;
	position: relative;
	display: block;
}
.grid .imageholder-books{
	background-size: cover;
	background-position: center center;
	width:100%;
	padding-top: 156.25%;
	overflow: hidden;
	position: relative;
	display: block;
}
.sold-out{
	background-color: #f01010;
	margin-top:10px;
	padding:5px;
}
.grid .imageholder img{
	position: absolute;
	width:100%;
  /* Position the image in the middle of its container. */
  top: -9999px;
  right: -9999px;
  bottom: -9999px;
  left: -9999px;
  margin: auto;
  /* The following values determine the exact image behaviour. */
  /* You can simulate background-size: cover/contain/etc.
     by changing between min/max/standard width/height values.
     These values simulate background-size: cover
  */
  min-width: 100%;
  min-height: 100%;	
}

.button.active{
    color: #666 !important;
    font-weight: 700 !important;
    
}

.pad-0 {
    padding: 5px;
    
}

.da-thumbs {
	list-style: none;
	padding-left:0px;
	overflow: hidden;
	
}

.da-thumbs li {
	display: block;
	position: relative;
	height:auto;
	overflow: hidden;
	
}

.da-thumbs li img {
	display: block;
	position: relative;
	width:100%;
}

.da-thumbs li .overlay {
	position: absolute;
	background: #000;
	background: rgba(0,0,0,0.5);
	width: 100%;
	height: 100%;
	/*padding: 20px;*/
	display: none;	
}
.clip-text {
    position: absolute;
    z-index: 99;
    width:170px;
    text-align: center;
    //color: #00D8D2; 
    color:#ffffff !important; 
    left:50%; 
    top:50%;
    margin-left:-85px; 
    margin-top:-10px; 
    
}
.clip-text h3{
	font-size: 16px;
	margin: 0px;
	font-weight: bold;
	color:#fff;
	
}

a.detail-close{
	float:right;
	font-size:50px;
	margin-top:-68px;
	  @media (max-width: 767px){
		  font-size:30px;
	margin-top:-47px;
		  }
	
}

/*Share icons*/
.share-icons{
	margin-top: 20px;
	width: 300px;
}
.share-icons a {
    font-family: "Arial";
    font-size: 11px !important;
    font-weight: 400 !important;
    color: #101010;
    margin-right: 10px;
    /* text-transform: capitalize; */
}
.share-icons a .fa{
	font-size: 14px !important;
	margin-right:5px !important; 
}



/* end portfolio */

/* Blog */
.card {
    border-radius: 0px !important;
    background-color: #F7F7F7;
    padding: 10px;
    pointer-events: none;
    box-shadow: none;
}
.card-body .titleborder{
	width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #232323;
    
}
.card-title{
	font-size: 20px;
	font-weight:bold;	
}
.first-word{
	color:#00D8D2; 
}
a.box-link {
    float: left;
    display: inline-block; 
    color: #232323;
    height: 40px;
    min-width: 40px;
    line-height: 40px;
    font-size: 16px;
    margin: 5px 0px 10px 0px;
    text-align: center;
    text-transform: uppercase;
    box-sizing: padding-box;
    padding: 0 8px;
    position: relative;
    background: rgba(255,255,255,.05);
    background: #F7F7F7;
    
}
a.box-link span {
    margin-right: 10px;
}
.box-link-text span, .box-link span {
    position: relative;
    display: inline-block;
    font-size: 12px;
    top: 0px;
    font-weight: 400;
}

/* end Blog. */


/* end Homepage */
.site{
    position: relative;
    z-index: 0;
    font-family: $font-family-sans-serif;
    &.transparent{
        background: transparent;
    }
    .container{
        //max-width: 970px;
    }
    .breadcrumb{
        background: white;
    }
    .wrapper{
       /* background:#F7F7F7; */
        background:transparent;
        overflow: hidden; 
        @media (max-width: 992px) {
			background-color: rgba(255,255,255,0.7);
			} 
             
        
    }
    .card-description{
        padding-top: 10px;
        a{
            color: $info-color;
            text-decoration: underline;
        }
        ul,ol{
            padding: 10px 0 20px;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
            }
            li {
                padding-left: 1em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
    }
    .list-group{
        .list-group-item{
            font-family: $title-font;
        }
        .list-group-item:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        .list-group-item:last-child {
          margin-bottom: 0;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
        }
    }
    .navbar{
        .navbar-nav{
            a{
                font-family: $title-font;
            }
            .dropdown-menu{
                border-radius: 1px;
                margin-left:9px;
                box-shadow: none;
                background-color: rgba(255,255,255,0.7);
                padding-left:10px;
                a{
				font-size: 0.8em !important;
                font-weight: 400 !important;
                color:#666 !important;
                }
                a:hover{
	            color:#101010 !important;   
                }
                li.active a{
	                color:#666 !important;
	                font-weight: 700 !important;
                }
                
                               
            }
            .dropdown-menu:before, .dropdown-menu:after{
	            display:none;
            }
            
        }
        .dropdown-menu .dropdown-item:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        .dropdown-menu .dropdown-item:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        .dropdown-menu li{
		    display: block !important;
			float:none !important;
			vertical-align:middle;   
			}
		
    }
    .navbar-brand{
        padding: 0;
        position: relative;
        //height: 77px;
        height: 89px;
        margin-left:15px;
        .logo{
            top: 0;
            position: absolute;
           // max-height: 40px;
           // margin-top: 28px;
            max-height: 87px;
            margin-top: 5px;
            
            @media (max-width: 575px) {
               margin-left: 0px;
            }
            span{font-weight: 300;}
        }
    }
    .navbar-toggler{
        @media (max-width: 575px) {
            position: relative;
            right: 15px;
        }
    }
    .logo_kleur{
        display: block;
    }
    .logo-wit{
        display: none;
    }
    .navbar-transparent{
        .logo_kleur{
            display: none;
        }
        .logo-wit{
            display: block;
        }
    }
    .header-wrapper{
        height: 550px;
    }
    .section{
        padding-top: 50px;
    }
    .section-home{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    h3.title-uppercase{
       margin-top: 50px;
    }
    p{
        margin-top: 0px;
    }
    .creators{
        margin-top: 100px;
    }
    .more-info{
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .section-with-breadcrumbs{
        padding-top: 5px;
    }
    .breadcrumb{
        margin-bottom: 0px;
    }
    .zoom-parent{
        display: block;
        position: relative;
        padding: 59.25% 0 0 0;
        //margin-top: 20px;
        //margin-bottom: 20px;
        overflow: hidden;
        border-radius: 4px;
        //border-radius: 12px;
        //-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        .zoom-child{
            //border-radius: 12px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: #aaa;
            background-repeat: no-repeat;
            background-position: center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            transition: all .9s;
            border-radius: 4px;
        }
        &:hover .zoom-child,
        &:focus .zoom-child {
            border-radius: 12px;
            transform: scale(1.1);
        }
    }
}
@mixin theme-mixing($color) {
    .list-group-item.active {
        background-color: lighten($color,10%);
        border-color: lighten($color,10%);
    }
    .dropdown-item{
        &.active{
            background-color: lighten($color,7%);
            color: white;
        }
    }
    .dropdown-item:hover{
        background-color: lighten($color,7%) !important;
        color: white !important;
    }
    .pagination > li > a,
    .pagination > li > span,
    .pagination > li:first-child > a,
    .pagination > li:first-child > span,
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border: 2px solid $color;
      color: $color;
    }
    .pagination > li > a:hover,
    .pagination > li > a:focus,
    .pagination > li > a:active,
    .pagination > li.active > a,
    .pagination > li.active > span,
    .pagination > li.active > a:hover,
    .pagination > li.active > span:hover,
    .pagination > li.active > a:focus,
    .pagination > li.active > span:focus {
      background-color: $color;
      border-color: $color;
      color: #FFFFFF;
    }
    .section.section-page-color{
        background-color: lighten($brown-color, 7%);
        color: white;
        h3{
            margin-top: 20px;
        }
    }
}
body{
    &.color-default{
        @include theme-mixing($default-color);
    }
    &.color-primary{
        @include theme-mixing($primary-color);
    }
    &.color-info{
        @include theme-mixing($info-color);
    }
    &.color-success{
        @include theme-mixing($success-color);
    }
    &.color-warning{
        @include theme-mixing($warning-color);
    }
    &.color-danger{
        @include theme-mixing($danger-color);
    }
}

body{
    color: #232323;
    //background-image: url('../img/chris1.jpg');
    height:100vh;
    background-position: center;
    background: no-repeat right bottom fixed;
    background-size: cover;
    //padding-top:95px;
    
    b, strong{
        //font-weight: 300;
    }
    p{
	    color:#666666;
    }
    .section-grey{
        background: #eee;
    }
    .section-white{
        background: white;
    }

    .list-group-item.active {
        z-index: 2;
        color: #fff;
        //background-color: #f5593d;
        //border-color: #f5593d;
    }
    h1.title,
    h2.title,
    h3.title,
    h4.title{
        font-size: 1.25em;
        line-height: 1.4;
        //margin: 35px 0 15px;
        //margin-left:5px;
        font-weight: 700;
        color:$black-color;
        text-transform: uppercase;
        font-family: $title-font;
        //margin-top: 50px;
        @media (max-width: 575px){
            font-size: 1.25em;
        }
        @media (max-width: 767px){
            font-size: 1.25em;
        }
        @media (max-width: 991px){
            font-size: 1.25em;
        }
    }
    h1.subtitle,
    h2.subtitle,
    h3.subtitle,
    h4.subtitle{
        font-size: 1.425em;
        line-height: 1.4;
        margin: 20px 0 20px;
        font-family: $title-font;
    }
    .title-row{
        h1.title,
        h2.title,
        h3.title,
        h4.title{
            margin-bottom: 40px;
            font-family: $title-font;
        }
    }
    .title-news{
        margin-bottom: 30px !important;
    }
    .card{
        border-radius: 4px;
        .card-title{
            font-family: $title-font;
        }
        .card-description{
            margin-top: 0;
            .btn{
                margin-top: 20px;
            }
        }
    }
    .card.card-blog .card-image .img,
    .card.card-testimonial .card-description + .card-title .card-image .img {
        border-radius: 4px;
    }
    .text-main{
        p{
            font-size: 16px;
        }
        ul,ol{
            padding: 10px 0 20px;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 16px;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                font-family: "Crimson Text", serif;
                font-weight: 400;
            }
            li {
                padding-left: 1em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
    }
    .intro{
        margin-bottom: 50px;
        margin-top: 70px;
        @media (max-width: 767px){
	        margin-bottom: 40px;
			margin-top: 20px;
			}
        
        p{
            font-size: 19px;
        }
        ul,ol{
            padding: 10px 0 10px;
            margin-bottom: 0;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 19px;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                font-family: "Crimson Text", serif;
                font-weight: 400;
            }
            li {
                padding-left: 1em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
    }
    .text-intro{
	    margin-top:40px;
        p{
            @media (min-width: 576px){
                margin-top: 0;
            }
            
            font-size: 20px;
        }
    }
    .footer.footer-big{
        background: transparent;
        color: $primary-color;
		padding: 10px 0;
		

        .social-link{
            padding: 8px;
            color: #aaa;
            font-size: 20px;
            @media (max-width: 576px){
	            padding: 3px !important;
	            }           
        }
        .social-link:hover{
	        color:  $black-color;
        }
        .copyright{
            color: $black-color;
        }
        
        .social-area {
			padding: 0px; 
			text-align: left;
		}
		
    }
    #footer_nav1,
    #footer_nav2{
        font-family: $title-font;
        .nav-link{
            padding: 0;
            color: white;
            font-family: $title-font;
        }
    }
    .footer-brand{
        img{
            display: block;
            width: 100%;
            max-width: 220px;
            margin-top: 19px;
        }
    }
    .home-contact{
        .image-landscape{
            display: block;
            position: relative;
            padding: 75% 0 0 0;
            //margin-top: 20px;
            //margin-bottom: 20px;
            overflow: hidden;
            margin-top: 10px;
        }
        h2.title{
            margin-top: 0;
            padding-top: 0;
        }
    }
}

a.social-link:first-child{
	padding-left:0px !important;
}

.vis{
	display:none;
	}

@media (max-width: 992px){
	.vis{
		display:block;
		line-height: 30px !important;		
	}
	.non-vis{
		display:none;
		}
	}
/*

    SWERK NOTIFICATIONS

*/
.swerk-notifcatie{
    position: fixed;
    z-index: 999999999999;
    top: -100px;
    left: 0;
    right: 0;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    margin-bottom: 0;
}
.swerk-notifcatie .alert{
    min-height: 81px;
    /*max-height: 100px;*/
    border: 0;
    border-radius: 0;
    color: white;
    overflow: hidden;
    box-shadow: 0;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
}
.swerk-notifcatie .alert p{
    float: left;
    font-size: 14px;
    line-height: 52px;
    margin-top: 0;
}
.swerk-not-title{
    display: inline-block;
    margin-right: 6px;
    line-height: 52px;
    height: 22px;
    font-weight: bold;
    position: relative;
    padding-left: 25px;
}
.swerk-notifcatie .alert i{
    font-size: 20px;
    line-height: 52px;
    position: absolute;
    left: 0;
    top: -1px;
}
.swerk-notifcatie .alert-success{
    background: #27c24c;
}
.swerk-notifcatie .alert-info{
    background: #23b7e5;
}
.swerk-notifcatie .alert-danger{
    background: #f05050;
}
.swerk-notifcatie .alert-warning{
    background: #fad733;
}
.swerk-notifcatie .alert .close{
    color: white;
    opacity: 1;
    position: absolute;
    right: 15px;
    top: 15px;
}
.swerk-notifcatie .alert .close i{
    font-size: 18px;
}