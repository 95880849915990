@font-face {
	font-family: 'flatline';
	src:url('../flatline-icons/flatline.eot?-45hfxq');
	src:url('../flatline-icons/flatline.eot?#iefix-45hfxq') format('embedded-opentype'),
		url('../flatline-icons/flatline.woff?-45hfxq') format('woff'),
		url('../flatline-icons/flatline.ttf?-45hfxq') format('truetype'),
		url('../flatline-icons/font-awesome-sassflatline.svg?-45hfxq#flatline') format('svg');
		
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'flatline';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-zoom-out:before {
	content: "\e600";
}
.icon-zoom-in:before {
	content: "\e601";
}
.icon-youtube:before {
	content: "\e602";
}
.icon-wrench:before {
	content: "\e603";
}
.icon-windows:before {
	content: "\e604";
}
.icon-warning-sign:before {
	content: "\e605";
}
.icon-volume-up:before {
	content: "\e606";
}
.icon-volume-off:before {
	content: "\e607";
}
.icon-volume-down:before {
	content: "\e608";
}
.icon-user:before {
	content: "\e609";
}
.icon-usd:before {
	content: "\e60a";
}
.icon-upload:before {
	content: "\e60b";
}
.icon-unchecked:before {
	content: "\e60c";
}
.icon-twitter:before {
	content: "\e60d";
}
.icon-tumblr:before {
	content: "\e60e";
}
.icon-tree-conifer:before {
	content: "\e60f";
}
.icon-trash:before {
	content: "\e610";
}
.icon-transfer:before {
	content: "\e611";
}
.icon-tower:before {
	content: "\e612";
}
.icon-tint:before {
	content: "\e613";
}
.icon-time:before {
	content: "\e614";
}
.icon-thumbs-up:before {
	content: "\e615";
}
.icon-thumbs-down:before {
	content: "\e616";
}
.icon-th:before {
	content: "\e617";
}
.icon-th-list:before {
	content: "\e618";
}
.icon-th-large:before {
	content: "\e619";
}
.icon-text-width:before {
	content: "\e61a";
}
.icon-text-height:before {
	content: "\e61b";
}
.icon-tasks:before {
	content: "\e61c";
}
.icon-tags:before {
	content: "\e61d";
}
.icon-tag:before {
	content: "\e61e";
}
.icon-stop:before {
	content: "\e61f";
}
.icon-step-forward:before {
	content: "\e620";
}
.icon-step-backward:before {
	content: "\e621";
}
.icon-stats:before {
	content: "\e622";
}
.icon-star:before {
	content: "\e623";
}
.icon-star-empty:before {
	content: "\e624";
}
.icon-sort:before {
	content: "\e625";
}
.icon-sort-by-order:before {
	content: "\e626";
}
.icon-sort-by-order-alt:before {
	content: "\e627";
}
.icon-sort-by-attributes:before {
	content: "\e628";
}
.icon-sort-by-attributes-alt:before {
	content: "\e629";
}
.icon-sort-by-alphabet:before {
	content: "\e62a";
}
.icon-sort-by-alphabet-alt:before {
	content: "\e62b";
}
.icon-signal:before {
	content: "\e62c";
}
.icon-shopping-cart:before {
	content: "\e62d";
}
.icon-share:before {
	content: "\e62e";
}
.icon-new-window:before {
	content: "\e62e";
}
.icon-share-alt:before {
	content: "\e62f";
}
.icon-send:before {
	content: "\e630";
}
.icon-search:before {
	content: "\e631";
}
.icon-screenshot:before {
	content: "\e632";
}
.icon-saved:before {
	content: "\e633";
}
.icon-save:before {
	content: "\e634";
}
.icon-road:before {
	content: "\e635";
}
.icon-retweet:before {
	content: "\e636";
}
.icon-resize-vertical:before {
	content: "\e637";
}
.icon-resize-small:before {
	content: "\e638";
}
.icon-resize-horizontal:before {
	content: "\e639";
}
.icon-resize-full:before {
	content: "\e63a";
}
.icon-repeat:before {
	content: "\e63b";
}
.icon-remove:before {
	content: "\e63c";
}
.icon-remove-sign:before {
	content: "\e63d";
}
.icon-refresh:before {
	content: "\e63e";
}
.icon-record:before {
	content: "\e63f";
}
.icon-random:before {
	content: "\e640";
}
.icon-question-sign:before {
	content: "\e641";
}
.icon-qrcode:before {
	content: "\e642";
}
.icon-pushpin:before {
	content: "\e643";
}
.icon-print:before {
	content: "\e644";
}
.icon-plus:before {
	content: "\e645";
}
.icon-plus-sign:before {
	content: "\e646";
}
.icon-play:before {
	content: "\e647";
}
.icon-play-circle:before {
	content: "\e648";
}
.icon-plane:before {
	content: "\e649";
}
.icon-pinterest:before {
	content: "\e64a";
}
.icon-picture:before {
	content: "\e64b";
}
.icon-phone:before {
	content: "\e64c";
}
.icon-phone-alt:before {
	content: "\e64d";
}
.icon-pencil:before {
	content: "\e64e";
}
.icon-pause:before {
	content: "\e64f";
}
.icon-paperclip:before {
	content: "\e650";
}
.icon-open:before {
	content: "\e651";
}
.icon-ok:before {
	content: "\e652";
}
.icon-ok-sign:before {
	content: "\e653";
}
.icon-off:before {
	content: "\e654";
}
.icon-music:before {
	content: "\e655";
}
.icon-move:before {
	content: "\e656";
}
.icon-minus:before {
	content: "\e657";
}
.icon-minus-sign:before {
	content: "\e658";
}
.icon-map-marker:before {
	content: "\e659";
}
.icon-magnet:before {
	content: "\e65a";
}
.icon-login:before {
	content: "\e65b";
}
.icon-log-out:before {
	content: "\e65c";
}
.icon-lock:before {
	content: "\e65d";
}
.icon-list:before {
	content: "\e65e";
}
.icon-list-alt:before {
	content: "\e65f";
}
.icon-link:before {
	content: "\e660";
}
.icon-leaf:before {
	content: "\e661";
}
.icon-italic:before {
	content: "\e662";
}
.icon-instagram:before {
	content: "\e663";
}
.icon-info-sign:before {
	content: "\e664";
}
.icon-indent-right:before {
	content: "\e665";
}
.icon-indent-left:before {
	content: "\e666";
}
.icon-inbox:before {
	content: "\e667";
}
.icon-import:before {
	content: "\e668";
}
.icon-home:before {
	content: "\e669";
}
.icon-heart:before {
	content: "\e66a";
}
.icon-heart-empty:before {
	content: "\e66b";
}
.icon-headphones:before {
	content: "\e66c";
}
.icon-header:before {
	content: "\e66d";
}
.icon-hdd:before {
	content: "\e66e";
}
.icon-hand-up:before {
	content: "\e66f";
}
.icon-hand-right:before {
	content: "\e670";
}
.icon-hand-left:before {
	content: "\e671";
}
.icon-hand-down:before {
	content: "\e672";
}
.icon-gplus:before {
	content: "\e673";
}
.icon-globe:before {
	content: "\e674";
}
.icon-glass:before {
	content: "\e675";
}
.icon-gift:before {
	content: "\e676";
}
.icon-gbp:before {
	content: "\e677";
}
.icon-fullscreen:before {
	content: "\e678";
}
.icon-foursquare:before {
	content: "\e679";
}
.icon-forward:before {
	content: "\e67a";
}
.icon-font:before {
	content: "\e67b";
}
.icon-folder-open:before {
	content: "\e67c";
}
.icon-folder-close:before {
	content: "\e67d";
}
.icon-floppy-saved:before {
	content: "\e67e";
}
.icon-floppy-save:before {
	content: "\e67f";
}
.icon-floppy-remove:before {
	content: "\e680";
}
.icon-floppy-open:before {
	content: "\e681";
}
.icon-floppy-disk:before {
	content: "\e682";
}
.icon-flash:before {
	content: "\e683";
}
.icon-flag:before {
	content: "\e684";
}
.icon-fire:before {
	content: "\e685";
}
.icon-filter:before {
	content: "\e686";
}
.icon-film:before {
	content: "\e687";
}
.icon-file:before {
	content: "\e688";
}
.icon-fast-forward:before {
	content: "\e689";
}
.icon-fast-backward:before {
	content: "\e68a";
}
.icon-facetime-video:before {
	content: "\e68b";
}
.icon-facebook:before {
	content: "\e68c";
}
.icon-eye-open:before {
	content: "\e68d";
}
.icon-eye-close:before {
	content: "\e68e";
}
.icon-export:before {
	content: "\e68f";
}
.icon-expand:before {
	content: "\e690";
}
.icon-exclamation-sign:before {
	content: "\e691";
}
.icon-euro:before {
	content: "\e692";
}
.icon-envelope:before {
	content: "\e693";
}
.icon-eject:before {
	content: "\e694";
}
.icon-edit:before {
	content: "\e695";
}
.icon-earphone:before {
	content: "\e696";
}
.icon-dropbox:before {
	content: "\e697";
}
.icon-dribbble:before {
	content: "\e698";
}
.icon-download:before {
	content: "\e699";
}
.icon-download-alt:before {
	content: "\e69a";
}
.icon-dashboard:before {
	content: "\e69b";
}
.icon-cutlery:before {
	content: "\e69c";
}
.icon-credit-card:before {
	content: "\e69d";
}
.icon-compressed:before {
	content: "\e69e";
}
.icon-comment:before {
	content: "\e69f";
}
.icon-collapse-up:before {
	content: "\e6a0";
}
.icon-collapse-down:before {
	content: "\e6a1";
}
.icon-cog:before {
	content: "\e6a2";
}
.icon-cloud:before {
	content: "\e6a3";
}
.icon-cloud-upload:before {
	content: "\e6a4";
}
.icon-cloud-download:before {
	content: "\e6a5";
}
.icon-circle-arrow-right:before {
	content: "\e6a6";
}
.icon-circle-arrow-left:before {
	content: "\e6a7";
}
.icon-chevron-up:before {
	content: "\e6a8";
}
.icon-chevron-right:before {
	content: "\e6a9";
}
.icon-chevron-left:before {
	content: "\e6aa";
}
.icon-chevron-down:before {
	content: "\e6ab";
}
.icon-check:before {
	content: "\e6ac";
}
.icon-certificate:before {
	content: "\e6ad";
}
.icon-camera:before {
	content: "\e6ae";
}
.icon-calendar:before {
	content: "\e6af";
}
.icon-bullhorn:before {
	content: "\e6b0";
}
.icon-briefcase:before {
	content: "\e6b1";
}
.icon-bookmark:before {
	content: "\e6b2";
}
.icon-book:before {
	content: "\e6b3";
}
.icon-bold:before {
	content: "\e6b4";
}
.icon-bell:before {
	content: "\e6b5";
}
.icon-barcode:before {
	content: "\e6b6";
}
.icon-ban-circle:before {
	content: "\e6b7";
}
.icon-backward:before {
	content: "\e6b8";
}
.icon-asterisk:before {
	content: "\e6b9";
}
.icon-arrow-up:before {
	content: "\e6ba";
}
.icon-arrow-right:before {
	content: "\e6bb";
}
.icon-arrow-left:before {
	content: "\e6bc";
}
.icon-arrow-down:before {
	content: "\e6bd";
}
.icon-apple:before {
	content: "\e6be";
}
.icon-android:before {
	content: "\e6bf";
}
.icon-align-right:before {
	content: "\e6c0";
}
.icon-align-left:before {
	content: "\e6c1";
}
.icon-align-justify:before {
	content: "\e6c2";
}
.icon-align-center:before {
	content: "\e6c3";
}
.icon-adjust:before {
	content: "\e6c4";
}
