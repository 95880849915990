.ekko-lightbox{
    .modal-dialog{
        background: none;
        .modal-content{
            background: none;
            border: 5px solid white;
            -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.35);
            -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.35);
            box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.35);
            border-radius: 0;
            .modal-header.hide{
                padding: 0;
                margin: 0;
                height: 0 !important;
                border: 0;
                background: none;
                position: relative;
                .close{
                    position: absolute;
                    bottom: -55px;
                    right: 24px;
                    z-index: 1050;
                    font-size: 48px;
                    color: white;
                }
            }
            .modal-body{
                padding: 0;
                margin: 0;
            }
            .modal-footer.hide{
                padding: 0;
                margin: 0;
                height: 0;
            }
        }
    }
}
